:root {
}

body {
    .ag-theme-alpine {
        --ag-header-background-color: #fff;
        --ag-border-color: #eaedf2;
        --ag-foreground-color: #4a5073;
    }
    .ag-theme-alpine {
        background-color: var(--ag-header-background-color);
        border-radius: 0.5rem;
        font-family: "Nunito Sans", sans-serif;
        font-weight: 400 !important;
        font-size: 0.875rem;
        white-space: nowrap;
        padding: 0 1.5rem 1.25rem;
        overflow: hidden;

        box-shadow: 0 2px 18px rgba(0, 0, 0, 0.1) !important;

        .ag-root-wrapper {
            border: 0;
        }

        .ag-header {
            border-bottom-width: 0.0625rem;
        }
        .ag-row {
            color: var(--ag-data-color, var(--ag-foreground-color, #181d1f));
        }
        .ag-row-hover {
            background-color: transparent !important;
            box-shadow: inset 0 0 0 9999px #eff0f2;
        }
        .ag-ltr .ag-cell,
        .ag-cell-value,
        .ag-group-value {
            line-height: 50px;
            height: 100%;
        }
    }
}
