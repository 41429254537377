/*
 * Sidebar
*/
.sidebar {
    display: block;
    position: fixed;
    top: 25px;
    bottom: 25px;
    left: 25px;
    z-index: 100;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
    @include border-radius(0.3rem);
    @include transition(max-width 0.3s);

    @include media-breakpoint-up(md) {
        width: 100%;
        max-width: 265px;
    }

    .menuicon {
        position: absolute;
        height: 30px;
        width: 30px;
        top: 31px;
        right: 10px;
        cursor: pointer;

        .line {
            background: white;
            width: 20px;
            height: 2px;
            border-radius: 2px;
            margin: 5px;
        }
        .line2 {
            width: 22px;
        }
        .line2 {
            width: 15px;
        }
    }

    .nav {
        white-space: nowrap;
    }

    .nav-item {
        .accordion-item {
            background: none;
            border: 0;
            .accordion-button:not(.collapsed) {
                box-shadow: none;
            }
        }
        &:first-child {
            padding: 25px;
            .nav-link {
                background: transparent !important;
            }
        }
        &.active {
            & > .nav-link {
                color: $light;
                background-color: #2e8eff;
            }
        }
        .nav-link {
            color: $light;
            &:hover {
                color: $light !important;
                background-color: #2e8eff !important;
            }
            &.active {
                color: $white;
            }
        }
    }

    .nav-link + .multi-level {
        margin-top: 0.2rem;
    }

    .nav-link {
        font-size: $font-size-base;
        vertical-align: middle;
        padding: 9px 25px;

        .sidebar-icon {
            margin-right: 0.5rem;
            color: $white;
            span {
                min-width: 22px;
            }
            svg {
                min-width: 22px;
            }
            &.svg-icon {
                margin-right: 14px;
            }
        }

        .link-arrow {
            font-size: $font-size-sm;
        }

        &:focus {
            outline: none;
        }
    }
    .nav-link.active {
        color: $primary;
    }

    .multi-level {
        .nav-link {
            padding-left: 60px;
        }
    }

    .sidebar-text,
    .link-arrow,
    .badge,
    .notification-count {
        opacity: 1;
        @include transition(opacity 0.3s);
    }

    @include media-breakpoint-up(md) {
        .sidebar-text-contracted {
            display: none;
            @include transition(opacity 0.3s);
        }

        &.contracted {
            .sidebar-text,
            .link-arrow,
            .badge {
                opacity: 0;
            }

            .notification-count {
                opacity: 1;
                position: absolute;
                top: 0;
                left: 35px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                font-size: 0.7rem;
                padding: 0.2rem;
            }

            .nav-item {
                position: relative;
                width: 47px;
                &.active {
                    & > .nav-link {
                        background-color: $primary;
                    }
                }
                .nav-link {
                    &:hover {
                        background-color: $primary;
                    }
                }
            }

            .sidebar-text-contracted {
                display: inline;
            }

            .multi-level .nav-link {
                padding-left: 17px;
            }

            .nav-item {
                white-space: nowrap;
            }

            .sidebar-icon {
                text-align: center;
            }

            @include media-breakpoint-up(md) {
                & + .content {
                    margin-left: 95px;
                }
            }

            max-width: 95px;
        }
    }
}

.sidebar-inner {
    position: relative;
    overflow-y: hidden;
    .accordion-button {
        background-color: transparent;
        @include border-radius(0 !important);
        border: 0;

        &::after {
            background-image: none;
            content: "\f054";
            font-family: $font-awesome-5;
            font-size: $font-size-sm;
            font-weight: 900;
            height: 1.25rem;
            width: 0.5rem;
        }
        &:not(.collapsed) {
            &::after {
                transform: rotate(90deg);
                transition: all 0.2s ease;
            }
        }
    }

    .accordion-body {
        padding: 0;

        .nav-item {
            padding: 0;
        }
    }

    .accordion-collapse {
        border: 0;
    }
}

.sidebar-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
}

.user-card {
    border-bottom: 0.0625rem solid #2e3650;
}

@include media-breakpoint-down(sm) {
    .sidebar {
        width: 100%;
    }
}

.content {
    overflow: visible;
    padding: 0 $spacer 0 $spacer;
    @include transition(all 0.3s);

    @include media-breakpoint-up(md) {
        margin-left: 290px;
        padding-left: 25px;
    }
}

.sidebar-toggle {
    font-size: 1.3rem;

    &:hover,
    &:focus {
        outline: none;
        box-shadow: none;
        background: $gray-400;
    }
}

.sidebar-transition-enter {
    opacity: 0;
}

.sidebar-transition-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}

.sidebar-transition-exit {
    opacity: 1;
}

.sidebar-transition-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}
