.wrapperExpandable {

    /*.accordion-wrapper {
        & + * {
            margin-top: 0.5em;
        }
    }*/

    .accordion-wrapper{
        &:first-child{
            .accordion-title {
                border-radius: 0.5rem 0.5rem 0 0;
                border-top: solid 1px rgba(46, 54, 80, 0.13);
            }
        }

        &:last-child{
            .accordion-title {
                border-radius: 0 0 0.5rem 0.5rem;
                -webkit-transition: border-radius 0.2s ease-in-out;
                   -moz-transition: border-radius 0.2s ease-in-out;
                    -ms-transition: border-radius 0.2s ease-in-out;
                        transition: border-radius 0.2s ease-in-out;

                &.open {
                    border-radius: 0;
                }
            }
        }
    }

    .accordion-item {
        overflow: hidden;
        transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
        height: auto;
        max-height: 9999px;

        border-radius: 0 !important;
        border: 0 !important;
    }

    .accordion-item.collapsed {
        max-height: 0;
        transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    }

    .accordion-title {
        font-weight: 600;
        cursor: pointer;
        color: #666;
        padding: 0.5em 1.5em;
        border: solid 1px rgba(46, 54, 80, 0.13);
        border-top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 1.25rem;
        background-color: #f5f8fb;

        h1, h2, h3, h4, h5, h6{
            margin: 0;
        }

        &::after {
            /*content: "";
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid currentColor;*/

            flex-shrink: 0;
            width: 1.25rem;
            height: 1.25rem;
            margin-left: auto;
            content: "";
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234a5073'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-size: 1.25rem;
            
            -webkit-transition: transform 0.2s ease-in-out;
               -moz-transition: transform 0.2s ease-in-out;
                -ms-transition: transform 0.2s ease-in-out;
                    transition: transform 0.2s ease-in-out;
        }

        /*&:hover,
        &.open {
            color: black;
        }*/

        &.open {
            &::after {
                content: "";
                /*border-top: 0;
                border-bottom: 5px solid;*/

                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23318ae6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                transform: rotate(-180deg);
            }
        }
    }

    .accordion-content {
        padding: 1em 1.5em;
        border: solid 1px rgba(46, 54, 80, 0.13);
        border-top: 0;
    }
}