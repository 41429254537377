@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap");

// Bootstrap
//@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


/* Write your project CSS/SCSS code here */
$primary-blue: #1266f1;
$secondary-blue: #6eb9f7;
$primary-grey: #6c757d;
$secondary-grey: #c0c6cc;
$addnew-teal-dark: #20c997;
$addnew-teal-light: #8bceba;
$active-green:#198754;
$info-highlight-blue: #a9e4f0;
$secondary-yellow: #f1e6c2;
$color-success: #20c997;
$color-warning: #f0ad4e;
$color-danger: #d63939;
$color-info:#5bc0de;

body{
    background: #f4f6fa;
}
.shadow-soft-web{
    box-shadow: 0 2px 20px rgba(0 ,0 ,0, 20%) !important;
}
.content{
    margin-left: 0 !important;
    padding: 0 1rem 0 1rem !important;

    a, a:hover, a:active {
        color: var(--bs-body-color);
    }
}
.navbar-light .navbar-nav .nav-link-carot a.nav-link::after,
.navbar-light .navbar-nav .dropdown a.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.btn-primary,.btn-secondary,.btn-addnew,.btn-info,.btn-delete,.btn-warning {
    border: none;
    border-radius: .25rem;
    padding: .625rem 1.5rem .5rem  1rem;
    //font-size: .75rem;
    //font-weight: 500 !important;
    //text-transform: uppercase;
    cursor: pointer;
    color: white;
    box-shadow:0 2px 5px 0 rgba(0, 0, 0 , 0.20), 0 2px 10px 0 rgba(0 ,0 ,0 , 0.10);
    -webkit-box-shadow:0 2px 5px 0 rgba(0 ,0, 0 , 0.20), 0 2px 10px 0 rgba(0, 0 ,0 , 0.10);
    outline: none;   
    background-position: center;
    transition: background 0.8s;     
    margin: 0 0 .25rem .8rem;

}



.btn-outline-sidetabs{
    border: none;
    border-bottom: 1px solid #dde2eb;
    border-radius: 0;
    width: 100%;
    margin: .25rem 0 .25rem .8rem;
    color: #000 !important;
    background-color: transparent !important;
    box-shadow: none;
    cursor: pointer;
    outline: none !important;

    
    &:after {
        background-image: none;
        content: "\f054";
        font-family: "Font Awesome 5 Free";
        font-size: 0.875rem;
        font-weight: 900;
        height: 1.25rem;
        width: 0.5rem;
        flex-shrink: 0;
        margin-left: auto;
        
    }

    &:hover { 
        color: white;             
        background: grey radial-gradient(circle, transparent 1%, grey 1%) center/15000% !important;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0,0.20), 0 4px 20px 0 rgba(0, 0 ,0 ,0.10);
        -webkit-box-shadow: 0 4px 10px 0 rgba(0 ,0 ,0, 0.20), 0 4px 20px 0 rgba(0, 0, 0 , 0.10);
        outline: none !important;
    }
    &:active {   
        color: white;           
        background-color: grey !important;
        background-size: 100%;
        transition: background 0s;
        outline: none !important;
    }  
}
.btn-outline-sidetabs-active{
    background-color: grey !important;
    outline: none !important;
}

.btn-primary{   
    color: white;
    background-color: $primary-blue;    
    &:hover { 
        color: white;             
        background: $primary-blue radial-gradient(circle, transparent 1%, $primary-blue 1%) center/15000%;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0,0.20), 0 4px 20px 0 rgba(0, 0 ,0 ,0.10);
        -webkit-box-shadow: 0 4px 10px 0 rgba(0 ,0 ,0, 0.20), 0 4px 20px 0 rgba(0, 0, 0 , 0.10);
    }
    &:active, &:focus {   
        color: white;           
        background-color: $secondary-blue;
        background-size: 100%;
        transition: background 0s;
    }  
    &:focus{
        box-shadow:0 0 0 0.25rem rgba(13, 110, 253, 0.5);
    }
}

.btn-primary-small-rounded, .btn-small-rounded{
    border: none;
    border-radius: .5rem !important;
    padding: 3px 13px !important;
}
.btn-secondary{
    color: white;
    background-color: $primary-grey;
    &:hover { 
        color: white;             
        background: $primary-grey radial-gradient(circle, transparent 1%, $primary-grey 1%) center/15000%;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0,0.20), 0 4px 20px 0 rgba(0, 0 ,0 ,0.10);
        -webkit-box-shadow: 0 4px 10px 0 rgba(0 ,0 ,0, 0.20), 0 4px 20px 0 rgba(0, 0, 0 , 0.10);
    }
    &:active,&:focus  {   
        color: white;           
        background-color: $secondary-grey;
        background-size: 100%;
        transition: background 0s;
    }  
    &:focus {   
        box-shadow:0 0 0 0.25rem rgba(108, 117, 125, 0.5);
    }
}

.btn-addnew{
    color: white;
    background-color: $addnew-teal-dark;
    &:hover { 
        color: white;             
        background: $addnew-teal-dark radial-gradient(circle, transparent 1%, $addnew-teal-dark 1%) center/15000%;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0,0.20), 0 4px 20px 0 rgba(0, 0 ,0 ,0.10);
        -webkit-box-shadow: 0 4px 10px 0 rgba(0 ,0 ,0, 0.20), 0 4px 20px 0 rgba(0, 0, 0 , 0.10);
    }
    &:active,&:focus {   
        color: white;           
        background-color: $addnew-teal-light;
        background-size: 100%;
        transition: background 0s;
    }  
    &:focus {   
        box-shadow:0 0 0 0.25rem rgba(0 ,0, 0, 0.10);
    }
}

.btn-delete{
    color: white;
    background-color: $color-danger;
    &:hover { 
        color: white;             
        background: $color-danger radial-gradient(circle, transparent 1%, $color-danger 1%) center/15000%;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0,0.20), 0 4px 20px 0 rgba(0, 0 ,0 ,0.10);
        -webkit-box-shadow: 0 4px 10px 0 rgba(0 ,0 ,0, 0.20), 0 4px 20px 0 rgba(0, 0, 0 , 0.10);
    }
    &:active,&:focus {   
        color: white;           
        background-color: $color-danger;
        background-size: 100%;
        transition: background 0s;
        border-color: $color-danger !important;
        outline: none !important;
    }  
    &:focus {   
        box-shadow:0 0 0 0.25rem rgba(225, 83 ,97, 50%);
    }
}

.btn-info{
    color: white;
    background-color: $color-info;
    &:hover { 
        color: white;             
        background: $color-info radial-gradient(circle, transparent 1%, $color-info 1%) center/15000%;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0,0.20), 0 4px 20px 0 rgba(0, 0 ,0 ,0.10);
        -webkit-box-shadow: 0 4px 10px 0 rgba(0 ,0 ,0, 0.20), 0 4px 20px 0 rgba(0, 0, 0 , 0.10);
    }
    &:active,&:focus {   
        color: white;           
        background-color: $info-highlight-blue;
        background-size: 100%;
        border-color: $info-highlight-blue !important;
        outline: none !important;
        transition: background 0s;
        
    }   
    &:focus {   
        box-shadow:0 0 0 0.25rem rgba(11 ,172, 204, 50%);
    }
}

.btn-warning{   
    color: white;
    background-color: $color-warning;    
    &:hover { 
        color: white;             
        background: $color-warning radial-gradient(circle, transparent 1%, $color-warning 1%) center/15000%;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0,0.20), 0 4px 20px 0 rgba(0, 0 ,0 ,0.10);
        -webkit-box-shadow: 0 4px 10px 0 rgba(0 ,0 ,0, 0.20), 0 4px 20px 0 rgba(0, 0, 0 , 0.10);
    }
    &:active,&:focus {   
        color: white;           
        background-color: $color-warning;
        background-size: 100%;
        border-color: $color-warning !important;
        outline: none !important;
        transition: background 0s;
    }  
    &:focus {   
        box-shadow:0 0 0 0.25rem rgba(255, 193, 7, 50%);
    }
}


span.link{ cursor:pointer; }
.text-active{ color:$active-green; }
.badge{ font-size: 14px; padding: 4px 6px; }
.grid-action-btns{ font-size: 1rem; }
label.required:after {
    content:" *";
    color:$color-danger;
}
input.is-invalid{
    border-color: $color-danger;
    &:focus{
        border-color: $color-danger;
    }
}
p.is-invalid{
    color:$color-danger;
}
.btn-icon-label{
    padding-right:5px;
}
.react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none;
}
.unverified-row{
    color: $color-danger !important;
}
.callout{
    padding: 1.25rem;    
    margin-bottom: 1.25rem;
    border: 0.0625rem solid rgba(46, 54, 80, 0.125);
    border-left-width: .25rem;
    border-radius: .5rem;  
}
.callout-info {
    border-left-color: $color-info;
}
.callout-danger {
    border-left-color: $color-danger;
}
.callout-warning {
    border-left-color: $color-warning;
}
.password-rules-box{
    border: 0.0625rem solid rgba(46, 54, 80, 0.125);
    border-left: .25rem solid #d63939;
    border-radius: 0.5rem;    
    padding: 10px;    
}
.footer{
    background: #fff;
    border-radius: 7px 7px 0 0;
    box-shadow: 0 0 15px -3px rgba(0,0,0,0.4);
    a, span  {
        text-decoration: none;
        color:rgba(46, 54, 80, 0.8);        
    }
    a:hover{
        text-decoration: underline;
    }
}


/* CSS for react-select optoin hover and focused list */
/* Start */
.custom-option {
  transition: background 60ms;
}
.custom-option:hover,
.custom-option.custom-select__option--is-focused:hover {
  transition-delay: 60ms;
  background: #deebff;
  cursor: pointer;
}

.custom-option.custom-select__option--is-focused {
  background: #ececec;

}
.custom-option.custom-select__option--is-selected {
  background: #2684FF;
}

.react-virtualized-menu-placeholder {
  margin-top: 12px;
  color: #9a9a9a;
  text-align: center;
}
.react-virtualized-list-wrapper li {
  list-style: none;
  overflow: hidden;
  line-height: 27px;
  border-bottom: 1px solid #000000;
}
/* End */



/* Start */
main{

    &.content{
        margin: 0 !important;
        padding: 81px 0 0 0 !important;
    }

    /* Header navigation CSS */
    /* Start */
    .navbar-light{

        .navbar-toggler{
            z-index: 99;

            &.collapsed{
                
            }
        }
        
    }
    .navbar-collapse{
        z-index: 98;

        @include media-breakpoint-down(lg) {
            width: 100%;
            right: 0;
            height: calc(100vh - 87px) !important;
            border-radius: 0 !important;
            top: 87px;
            overflow: auto;
        }
    }
    /* End */
}
/* End */

/* grid-table CSS */
/* Start */

.grid-table{
    background-color: #EBEEF2;
    color: rgb(74, 80, 115);

    ul{
        padding-left: 25px;
        margin: 0;
    }
    li,
    .col{
        font-size: 0.875rem;
    }

    .col{
        padding: 0.50rem 0.75rem;
        border-top: 0;

        @include media-breakpoint-up(md) {
            padding: 0.75rem 1.5rem;
        }
    }

    .row-header{
        background-color: #ffffff;
        border-bottom: 1px solid #000;

        .col{
            font-size: 0.75rem;
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    .row-body{

        .row{
            &:nth-child(even){
                background-color: #F5F8FB;
            }

            &:nth-child(odd){
                background-color: transparent;
            }
        }

        .col,
        li{
            font-size: 12px;
            
            @include media-breakpoint-up(md) {
                font-size: 0.875rem;
            }
        }
    }
}

/* End */


.personnames-table > :not(caption) > * > * {
    padding: 0.3rem 0.5rem;
}
.navbar{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}