@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap");

// Bootstrap mixins and functions
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "volt/variables";

// Bootstrap
//@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "~bootstrap/scss/bootstrap";

// Vendor
@import "volt/vendor";

// volt mixins & functions
@import "volt/mixins";
@import "volt/functions";

// Utilities
@import "volt/reboot";
@import "volt/utilities";

// Layout
@import "volt/layout";

// Components
@import "volt/components";

//AG grid styling
@import "AG-grid";

html,
body {
    height: 100%;
    overflow: hidden;
}

body,
body * {
    font-family: "Nunito Sans", sans-serif;
}

body {
    background: #eff1f5;
    overflow: auto;
}

.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-success,
.btn-warning {
    color: #fff !important;
}

#ofBar {
    display: none !important;
}

/* Dropzone CSS */
/* Start */
.dropZoneContainer {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
}

.dropZoneContainer > p {
    font-size: 1rem;
}

.dropZoneContainer > em {
    font-size: 0.8rem;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
}

.dropzone:focus {
    border-color: #2196f3;
}

.dropzone.disabled {
    opacity: 0.6;
}
/* End */
